<template>
  <basic-block :title="currentTerm.termName" class="leavel_scholl">
    <template #search>
      <el-row :gutter="12">
        <el-col :span="6">
          <el-date-picker
            :size="$publicConfig.selectSize"
            v-model="search.date"
            type="date"
            style="width: 100%"
            placeholder="选择日期"
            format="yyyy-MM-dd 00:00:00"
            value-format="timestamp"
            @change="getTableData"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="search.campusCode"
            :size="$publicConfig.selectSize"
            placeholder="请选择校区"
            @change="getschool"
          >
            <el-option
              v-for="item in allschool"
              :key="item.campusCode"
              :label="item.campusName"
              :value="item.campusCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="search.gradeId"
            :size="$publicConfig.selectSize"
            placeholder="请选择年级"
            @change="
              () => {
                search.classCode = allClass[0].classCode
                getTableData()
              }
            "
          >
            <el-option
              v-for="item in allGrade"
              :key="item.gradeCode"
              :label="item.grade"
              :value="item.gradeCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            v-model="search.classCode"
            :size="$publicConfig.selectSize"
            placeholder="请选择班级"
            @change="getTableData"
          >
            <el-option
              v-for="item in allClass"
              :key="item.classCode"
              :label="item.className"
              :value="item.classCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <div class="content_table">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="grade" label="年级"></el-table-column>
        <el-table-column prop="className" label="班级"></el-table-column>
        <el-table-column prop="studentName" label="学生"></el-table-column>
        <el-table-column prop="status" label="是否离班">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isAfterSchool == '未离校'" type="danger"
              >未离校</el-tag
            >
            <el-tag v-else>已离校</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="afterSchoolType"
          label="离班类型"
        ></el-table-column>
        <el-table-column prop="time" label="离校时间"></el-table-column>
        <el-table-column
          prop="checkInAmount"
          label="打卡次数"
        ></el-table-column>
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.checkInRecord"
              placement="bottom"
              width="250"
              trigger="click"
              popper-class="leavel_scholl_pop"
            >
              <div class="tips_main">
                <div class="title">打卡详情</div>
                <div class="times" v-for="(v, k) in records" :key="k">
                  <span>第{{ k + 1 }}次打卡(</span>
                  <span class="im">{{ $cftY(v.time).split(' ')[1] }}</span>
                  <span>)</span>
                </div>
              </div>
              <el-link
                slot="reference"
                @click="getAttendanceLog(scope.row)"
                type="primary"
                >点击查看</el-link
              >
            </el-popover>
            <el-link v-else slot="reference" disabled type="primary"
              >点击查看</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </basic-block>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getAfterSchoolRecord,
  getAttendanceLog,
  getSchoolTerm
} from '@/http/leaveSchool'

export default {
  data() {
    return {
      dialogFormVisible: false,
      search: {
        date: new Date(new Date(new Date().toLocaleDateString())).getTime(),
        gradeId: '',
        classCode: '',
        campusCode: ''
      },
      detail: {},
      records: [],
      tableData: [],
      currentTerm: {},
      allClass: [],
      allGrade: []
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'allNGrade', 'allNClass', 'token']),
    allschool() {
      this.allNGrade.forEach((item) => {
        if (item.campusCode == this.search.campusCode) {
          this.allGrade = item.gradeList
          item.gradeList.forEach((items) => {
            items.classList.forEach((itemss) => {
              if (itemss.classCode == this.search.classCode) {
                this.allClass = items.classList
              }
            })
          })
        }
      })
      return [...this.allNGrade]
    }
    // allGrade() {
    //   this.allschool.forEach(item=>{
    //     console.log(item)
    //   })
    //   return [
    //     // {
    //     //   gradeCode: "",
    //     //   gradeName: "全部年级",
    //     // },
    //     ...this.allNGrade,
    //   ];
    // },
    // allClass() {
    //   return [
    //     // {
    //     //   className: "全部班级",
    //     //   classCode: "",
    //     // },
    //     ...this.allNClass.filter(
    //       (item) => item.gradeInfo.gradeCode == this.search.gradeId
    //     ),
    //   ];
    // },
  },
  components: {},
  async created() {
    this.search.gradeId = JSON.parse(this.$route.query.v).gradeId
    this.search.classCode = JSON.parse(this.$route.query.v).classCode
    this.search.campusCode = this.$route.query.campusCode
    this.getTableData()
    this.getSchoolTerm()
  },
  methods: {
    getSchoolTerm() {
      let params = {
        schoolId: this.userInfo.tnId,
        termId: 0
      }
      getSchoolTerm(params).then((res) => {
        this.currentTerm = res.data
      })
    },
    getTableData() {
      this.search.schoolId = this.userInfo.tnId
      getAfterSchoolRecord(this.search).then((res) => {
        if (res.retCode == 1) {
          this.tableData = res.data
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    goto(v) {
      this.$router.push({
        path: '/attendance/leaveSchool/' + v.id
      })
    },
    getAttendanceLog(row) {
      let params = {
        recordId: row.checkInRecord,
        type: row.type
      }
      getAttendanceLog(params).then((res) => {
        if (res.retCode == 1) {
          this.records = res.data
        } else {
          this.$message.error(res.retMsg)
        }
      })
    },
    //校区选择
    getschool(e) {
      console.log(e)
      // this.allGrade = []
      this.allschool.forEach((item) => {
        if (e == item.campusCode) {
          this.allGrade = item.gradeList
          this.search.gradeId = this.allGrade[0].gradeCode
          this.search.classCode = this.allGrade[0].classList[0].classCode
          this.getTableData()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.leavel_scholl_pop {
  padding: 0 !important;
  .tips_main {
    div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding: 9px 16px;
    }
    font-size: 14px;
    .title {
      color: #333333;
      font-weight: 500;
    }
    .times {
      .im {
        color: #00c9d9;
      }
    }
  }
}
</style>